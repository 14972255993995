export default {
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potražite"])},
  "kontakt": {
    "adresa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Momira Gajića 11, Velika Plana, Srbija"])},
    "telefon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["060 414-20-27"])},
    "wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wifi lozinka"])}
  },
  "glavni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Glavni meni"])},
  "cene": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cene"])},
  "cena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cena"])},
  "opis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opis"])},
  "dodaci": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaci"])},
  "nemadodataka": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nema dodataka"])},
  "nemarezultata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nema rezultata"])},
  "prazno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prazna kategorija"])},
  "mala": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mala ɸ22"])},
  "srednja": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Srednja ɸ33"])},
  "velika": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velika ɸ40"])},
  "porodicna": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porodična ɸ45"])},
  "rezultati": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rezultati pretrage"])},
  "nisteuneli": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unesite tekst za pretragu"])}
}