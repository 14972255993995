<template>
    <select v-model="$i18n.locale" @change="onChange($event)">
        <option v-for="(locale, i) in locales" :key="`locale-${i}`" :value="locale.short" style="padding-left: 10px">
            {{ locale.name }}
        </option>
    </select>
</template>
  
<script>
import axios from 'axios';

export default {
    name: "LocaleSwitcher",
    data() {
        return {
            locales: [],
            base_api: process.env.VUE_APP_FRONT_API
        };
    },
    mounted() {
        axios.get(this.base_api + "languages")
            .then(response => {
                this.locales = response.data;
            })
            .catch(error => {
                console.log(error);
                this.product = [{ name: 'No loaded data' }];
            })
          //  .finally(() => console.log('...'));
    },
    methods: {
        onChange(event) {
            if (this.$route.name == "categories") {
                let path = event.target.value;
                if (this.$route.params.id)
                    path += '/' + this.$route.params.id;
                
                this.$router.push({ path: '/' + path })
            }

            if (this.$route.name == "products") {
                let path = event.target.value + '/products';
                if (this.$route.params.id)
                    path += '/' + this.$route.params.id;
                
                this.$router.push({ path: '/' + path })
            }

            if (this.$route.name == "product") {
                let path = event.target.value + '/product';
                if (this.$route.params.id)
                    path += '/' + this.$route.params.id;
                
                this.$router.push({ path: '/' + path })
            }
            
        }
    }
};
</script>

<style scoped>
option {}

select {

    border: 2px solid white;
    border-radius: 6px;
    padding: 2px 22px 2px 6px;
    box-shadow: var(--shadow-2);
    text-transform: capitalize;
    background-size: 12px;
}
</style>
  