import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

import './assets/css/style.css';
import i18n from './i18n'

/*
router.beforeEach((to, from, next) => {
    let language = to.params.lang;
    if (!language) {
      language = 'en'
    }
  
    i18n.locale = language
    next()
  });
  */


createApp(App).use(i18n).use(store).use(router).mount("#app");
