export default {
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
  "kontakt": {
    "adresa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Momira Gajica 11, Velika Plana, Serbia"])},
    "telefon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["060 414-20-27"])},
    "wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wifi password"])}
  },
  "glavni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main menu"])},
  "cene": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prices"])},
  "cena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
  "opis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "dodaci": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extras"])},
  "nemadodataka": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nema dodataka"])},
  "nemarezultata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There's no results"])},
  "prazno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empty category"])},
  "mala": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Small ɸ22"])},
  "srednja": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Middle ɸ33"])},
  "velika": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Big ɸ40"])},
  "porodicna": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Family ɸ45"])},
  "rezultati": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search results"])},
  "nisteuneli": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter search text"])}
}