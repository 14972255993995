import { createRouter, createWebHistory } from "vue-router";
import MainView from '../views/MainView';
//import i18n from '../i18n';

//console.log("ruter kaze: " + i18n.locale);

const routes = [
  {
    path: "/",
    redirect: "/rs"
  },
  {
    path: "/:lang",
  //  component: RouterView,
    children: [
      {
        path: "/:lang/:id?",
        name: "categories",
        component: MainView,
      },
      {
        path: "/:lang/products/:id?",
        name: "products",
        component: MainView,
      },
      {
        path: "/:lang/product/:id",
        name: "product",
        component: MainView,
      },
    ]
  }
];
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
