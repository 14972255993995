<template>
	<div>
		<div class="row">
			<div class="col-md-3"></div>
			<div class="col-md-6 px-0" style="background-color: white;">
				<div class="col-md-12 header">
					<div class="back" v-if="$route.params.id" @click="$router.back()">
						<i class="fa fa-arrow-left" style="color: gray"></i>
					</div>
		<!--			<div class="btn-group pull-right" style="margin-top: 10px; margin-right: 10px; ">
						<button type="button" class="btn btn-danger btn-sm dropdown-toggle" data-bs-toggle="dropdown"
							data-bs-display="static" aria-expanded="false">
							Izaberite...
						</button>
						<ul class="dropdown-menu dropdown-menu-lg-end">
							<li>
								<button class="dropdown-item" type="button" style="color: brown">Srpski
									<img src="@/assets/images/flags/rs.png" class="pull-right"
										style="width: 25px; margin-top: 3px;">
								</button>
							</li>
							<li>
								<button class="dropdown-item" type="button" style="color: brown">Engleski
									<img src="@/assets/images/flags/gb.png" class="pull-right"
										style="width: 25px; margin-top: 3px;">
								</button>
							</li>
							<li>
								<button class="dropdown-item" type="button" style="color: brown">Ruski
									<img src="@/assets/images/flags/ru.png" class="pull-right"
										style="width: 25px; margin-top: 3px;">
								</button>
							</li>
						</ul>
					</div> -->

					<LocaleSwitcher class="pull-right" style="margin-top: 90px; margin-right: 10px;"/>
				</div>
				<div class="col-md-12 main">
					<h1 class="title">Caffe Vertigo</h1>
					
					<div class="col-md-12 px-0" style="color: #989898; font-size: 11pt; margin-top: 10px">
						<div class="row">
							<div class="col-md-8" style="margin-bottom: 6px; padding-right: 0px">
								<i class="fa fa-map-marker" style="margin-right: 8px">
								</i> <a href="https://goo.gl/maps/R32Zm3WfgwTqPSse8" class="linkic" target="_blank">{{ $t('kontakt.adresa') }}</a>
							</div>
							<div class="col-md-12" style="margin-bottom: 2px">
								<span style="margin-right: 30px; float: left">
									<i class="fa fa-phone" style="margin-right: 5px;"></i> {{ company.phone }}
								</span> 
								<div style="float: left;margin-top: -2px">
									<i class="fa fa-wifi" style="margin-right: 2px"></i> {{ company.wifi }}
								</div>
								
							</div>
						</div>
						
					</div>
					<router-view :key="$route.fullPath"/>
				</div>
				<div class="col-md-12 text-center mb-2" style="font-size: 9pt;color: #b5b5b5">caffe-vertigo.com</div>
			</div>
			<div class="col-md-3"></div>

		</div>
		
	</div>
</template>
<script>
import axios from 'axios';
import LocaleSwitcher from "@/components/LocaleSwitcher.vue";
export default {
	components: {
		LocaleSwitcher
	},
	data() {
		return {
			company: '',
			base_api: process.env.VUE_APP_FRONT_API
		}
	},
	mounted() {
        axios.get(this.base_api + "company")
            .then(response => {
                this.company = response.data;
            })
            .catch(error => {
                console.log(error);
                this.company = [{ name: 'No loaded company' }];
            })
         //   .finally(() => console.log('Loaded company'));
	}
}
</script>
<style  scoped></style>
