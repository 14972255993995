<template>
    <div v-if="isLoaded">
        <h5 v-if="search" class="rezultati">{{ $t('rezultati') }}:</h5>
        <div v-if="filteredProducts.length > 0">
            <div v-if="currRouteName == 'categories'">
                <div class="col-md-12 px-0" v-for="product in filteredProducts" :key="product.id">
                    <div class="col-md-12 category" @click="opensubs(product.id, product.subcats.length)"
                        :style="{ backgroundImage: 'url(' + getImg(product.image) + ')', width: '100%' }">
                    </div>
                    <div class="category_name" @click="opensubs(product.id, product.subcats.length)">{{
                        product.name_trans }}</div>
                </div>
            </div>
            <div class="row" v-else-if="currRouteName == 'products'">
                <div class="col-md-6 product" v-for="product in filteredProducts" :key="product.id"
                    @click="openproduct(product.id)">
                    <img :src="getImg(product.image)" class="imgproduct">
                    <div class="col-md-12 jelo_naslov" style="margin-top: 5px">{{ product.name_trans }}</div>
                </div>
            </div>
        </div>
        <div v-else-if="search">
            <div class="col-md-12 text-center nema">
                {{ $t('nemarezultata') }}
            </div>
        </div>
        <div v-else style="height: 400px">
            <div class="col-md-12 text-center nema">
                {{ $t('prazno') }}
            </div>
        </div>
    </div>
    <div v-else>
        <div class="col-md-12 text-center" style="margin-top: 900px">
            <div class="spinner-grow text-secondary" role="status">
                <div class="spinner-border text-light" style="width: 3rem; height: 3rem;" role="status">
                    <span class="sr-only"></span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import axios from 'axios';

export default {
    name: "CategoriesComponent",
    props: {
        search: String
    },
    data() {
        return {
            isLoaded: false,
            products: [],
            storage: process.env.VUE_APP_FRONT_IMAGES,
            base_api: process.env.VUE_APP_FRONT_API
        }
    },
    mounted() {
        let path = (this.$route.params.id) ? this.base_api + "products/" + this.$route.params.id : this.base_api + 'products';
        path += "?lang=" + this.$route.params.lang;

        axios.get(path)
            .then(response => {
                this.products = response.data;
                this.isLoaded = true;
            })
            .catch(error => {
                console.log(error);
                this.products = [{ name: 'No loaded products' }];
            });
       //     .finally(() => console.log('Product loaded'));

        this.$i18n.locale = this.$route.params.lang;
    },
    computed: {
        filteredProducts() {
            if (this.search)
                return this.products.filter(product => product.name_trans.toLowerCase()
                    .match(this.search.toLowerCase()));
            else
                return this.products;
        },
        brojac() {
            return this.products.length;
        },
        currRouteName() {
            return this.$route.name;
        }
    },
    methods: {
        getImg(image) {
            return (image) ? this.storage + '/' + image : require('@/assets/images/blankogray.jpg');
        },

        opensubs(id, catcounter) {
            if (catcounter > 0)
                return this.$router.push({ path: '/' + this.$i18n.locale + '/' + id });
            else
                return this.$router.push({ path: '/' + this.$i18n.locale + '/products/' + id });
        },

        openproduct(id) {
            return this.$router.push({ path: '/' + this.$i18n.locale + '/product/' + id });
        }
    }
}
</script>

