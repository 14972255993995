<template>
    <div v-if="$route.params.id" style="margin-top: 20px">
        <div class="col-md-12" style="margin-top: 0px; margin-bottom: 0px;">
            <button class="glavni_menu" @click="goToHome"><span>{{ $t('glavni') }}</span></button>
            <button class="sub_menu" @click="opensubs(product.id, product.subcats.length)"
                v-if="product.name_trans"><span>{{ product.name_trans }}</span></button>
        </div>
    </div>
    <div class="col-md-12" v-if="currRouteName == 'product'">
        <ProductsComponent />
    </div>
    <div class="col-md-12" v-else>
        <div class="seach-form" style="margin-top: 15px;">
            <div class="seach-form-field">
                <input type="search" :placeholder="$t('search')" class="seach-field" v-model="temp"
                    v-on:keyup.enter="onEnter">
                <button class="round-button seach-form-button" @click="setTekst">
                    <i class="fa fa-search fa-rotate-90" style="position: absolute; top: 9px; right: 9px; color: gray;"></i>
                </button>
            </div>
        </div>
        <div class="col-md-12 poruka" v-if="message">{{ message }}
        </div>
        <CategoriesComponent :search="search" />
    </div>
</template>
  
<script>

import axios from 'axios';

import ProductsComponent from "@/components/ProductsComponent.vue";
import CategoriesComponent from "@/components/CategoriesComponent.vue";

export default {
    name: "MainView",
    components: {
        CategoriesComponent,
        ProductsComponent
    },
    data() {
        return {
            message: '',
            search: '',
            temp: '',
            product: '',
            base_api: process.env.VUE_APP_FRONT_API
        }
    },
    mounted() {
        if (this.$route.params.id) {
            axios.get(this.base_api + "product/" + this.$route.params.id + "?lang=" + this.$route.params.lang)
                .then(response => {
                    this.product = response.data;
                })
                .catch(error => {
                    console.log(error);
                    this.product = [{ name: 'No loaded data' }];
                })
             //   .finally(() => console.log('...'));
        }
    },
    computed: {
        currRouteName() {
            return this.$route.name;
        }
    },
    methods: {
        setTekst() {
            this.search = this.temp;
            if (!this.temp) {
                this.message = this.$i18n.t('nisteuneli');
                setTimeout(() => {
                    this.message = "";
                }, 2000);
            }
        },

        onEnter() {
            this.setTekst();
        },

        opensubs(id, catcounter) {
            if (catcounter > 0)
                return this.$router.push({ path: '/' + this.$i18n.locale + '/' + id });
            else
                return this.$router.push({ path: '/' + this.$i18n.locale + '/products/' + id });
        },

        goToHome() {
            return this.$router.push({ path: '/' + this.$i18n.locale });
        }
    }
};
</script>
<style scoped>
.poruka {
    color: #bd7d62;
    margin-top: -15px;
    margin-bottom: 20px;
    padding-left: 3px;
}</style>