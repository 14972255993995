<template>
    <div class="row">
        <div class="col-md-12" style="margin-bottom: 40px; margin-top: 20px" v-if="product">
            <img :src="getImg(product.image)" style="width: 100%; border-radius: 20px; margin-top: 0px">
            <div class="col-md-12">
                <div class="row" style="margin-top: 10px">
                    <div class="col-md-12 jelo_naslov" style="font-size: 20px;">{{ product.name_trans }} <span
                            class="pull-right cena2">{{ product.amount }}</span></div>
                    <div class="col-md-12 cena" v-if="!product.moreprices">{{ numFormat(product.price) }} RSD</div>
                    <div class="col-md-12" style="margin-top: 10px; margin-bottom: 20px;" v-else>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="col-md-12 py-2 px-2" style="background-color: #fafafa;">
                                    <div class="col-md-12 jelo_naslov" style="margin-bottom: 10px">{{ $t('cene') }} </div>
                                    <div class="col-md-12 cenanatpis">{{ $t('mala') }} <span class="pull-right cena2">{{
                                        formatPrices(product.price) }}</span></div>
                                    <div class="col-md-12 cenanatpis">{{ $t('srednja') }} <span class="pull-right cena2">{{
                                        formatPrices(product.price2) }}</span></div>
                                    <div class="col-md-12 cenanatpis">{{ $t('velika') }}<span class="pull-right cena2">{{
                                        formatPrices(product.price3) }}</span></div>
                                    <div class="col-md-12 cenanatpis">{{ $t('porodicna') }} <span class="pull-right cena2">{{
                                        formatPrices(product.price4) }}</span></div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="col-md-12 jelo_naslov mb-2">{{ $t('opis') }}</div>
                <div class="col-md-12">
                    {{ product.description }}
                </div>
            </div>
            <div class="col-md-12" style="margin-top: 30px">
                <div class="col-md-12 jelo_naslov dodaci"
                    style="border-bottom: 1px solid gray; padding-bottom: 10px; margin-bottom: 20px">
                    {{ $t('dodaci') }}
                    <i class="fa fa-star-o"></i>
                    <i class="fa fa-star-o"></i>
                    <i class="fa fa-star-o"></i>
                </div>
                <div class="col-md-11 px-0">
                    <div class="row" v-if="brojac > 0">
                        <div class="col-md-6" v-for="extra in extras" :key="extra.id">
                            <div class="col-md-12 jelo_naslov">{{ extra.name }}</div>
                            <img :src="getImg(extra.image)" class="imgproduct">
                            <div class="col-md-12">
                                <div class="row" style="margin-top: 5px">
                                    <div class="col-md-12 cena_extra">{{ formatPrices(extra.price) }} <span
                                            class="pull-right cena2" style="color: gray">{{
                                                extra.amount }}</span></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <i>{{ $t('nemadodataka') }}</i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import axios from 'axios';

export default {
    name: "ProductsComponent",
    props: {
        search: String
    },
    data() {
        return {
            product: '',
            storage: process.env.VUE_APP_FRONT_IMAGES,
            base_api: process.env.VUE_APP_FRONT_API
        }
    },
    mounted() {
        axios.get(this.base_api + "product/" + this.$route.params.id)
            .then(response => {
                this.product = response.data;
                //   console.log(this.product);
            })
            .catch(error => {
                console.log(error);
                this.product = [{ name: 'No loaded data' }];
            });
        //    .finally(() => console.log('...'));
        
        this.$i18n.locale = this.$route.params.lang;
    },
    computed: {
        currRouteName() {
            return this.$route.name;
        },
        extras() {
            return this.product.subextras;
        },
        brojac() {
            return this.product.subextras ? this.product.subextras.length : 0
        },

        imadodatke() {
            return (this.product.subextras) ? true : false;
        },
    },
    methods: {
        getImg(image) {
            return (image) ? this.storage + '/' + image : require('@/assets/images/blankogray.jpg');
        },
        numFormat(value) {
            return parseFloat(value).toFixed(2);
        },
        formatPrices(value) {
            if (value != -1)
                return parseFloat(value).toFixed(2) + ' RSD';
            else
                return '/';
        },
        openproduct(id) {
            return this.$router.push({ path: '/product/' + id });
        },

        goToHome() {
            return this.$router.push({ path: '/' });
        },
        getExtras() {
            console.log(this.product.subextras);
        },
        getProduct() {
            console.log(this.product);
        }
    }
}
</script>

