export default {
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск"])},
  "kontakt": {
    "adresa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Момира Гајића 11, Велика Плана, Сербия"])},
    "telefon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["060 414-20-27"])},
    "wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wifi lozinka"])}
  },
  "glavni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Главное меню"])},
  "cene": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цены"])},
  "cena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цена"])},
  "opis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Описание"])},
  "dodaci": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приложения"])},
  "nemadodataka": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Без приложений"])},
  "nemarezultata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Без результатов"])},
  "prazno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пустая категория"])},
  "mala": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Маленькая ɸ22"])},
  "srednja": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Средний размер ɸ33"])},
  "velika": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Большой размер ɸ40"])},
  "porodicna": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Семейная ɸ45"])},
  "rezultati": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Результаты поиска"])},
  "nisteuneli": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите текст для поиска"])}
}